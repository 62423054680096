body {
    font-family: 'Nunito', sans-serif;
}
.title-bar svg {
    color: coral
}
.title-bar span {
    color: cornflowerblue
}
.letter-input{
    background-color: #e7eff6;
    color: transparent;
    text-shadow: 0 0 0 #000;
}
.word-item span {
    color: red
}